<template>
  <div class="d-flex full-height full-width flex-1">
    <dynamic-data-table
      :export-file-name="`${dhmeBillOfMaterialFocusedGroup.name}_${
        selectedElement ? `${selectedElement}_` : ''
      }objects`"
      :has-options="true"
      :is-loading="tableLoading"
      :table-headers="tableHeaders"
      :table-records="tableRecords"
      :table-title="`${dhmeBillOfMaterialFocusedGroup.name} Objects`"
      :csv-export-function="exportToCsv"
      auto-sort-column="object_type"
      class="full-width"
    >
      <template
        v-for="tmp in tableHeaders"
        #[`header.${tmp.value}`]="{ header }"
      >
        <th :key="tmp.value">{{ tmp.text }} ({{ getHeaderTotal(tmp) }})</th>
      </template>
      <template
        v-for="tmp in tableHeaders"
        #[`item.${tmp.value}`]="{ value, rowId, item }"
      >
        <td :key="tmp.value">
          {{ value.count }}
        </td>
      </template>
      <template #item.check="{ value, rowId, item }">
        <div class="d-flex">
          <v-icon v-if="selectedElement" small class="mr-1">
            {{
              checkObjectTypeCount(item) % 1 != 0
                ? 'mdi-alert-circle'
                : 'mdi-check-circle'
            }}
          </v-icon>
          {{ selectedElement ? checkObjectTypeCount(item) : '-' }}
        </div>
      </template>
      <template #table-actions>
        <v-alert
          v-if="invalidData.length > 0"
          border="left"
          class="ma-0 mr-2"
          dense
          type="warning"
        >
          {{ text('incorrectObjectsDataSetWarning', invalidData.length) }}
        </v-alert>
        <v-autocomplete
          v-model="selectedElement"
          :items="elements"
          :placeholder="text('element')"
          clearable
          dense
          filled
          hide-details
          style="max-width: 200px"
        />
        <div v-if="selectedElement" class="mx-2">
          {{ elementCount }} {{ $t('general.numberOfAbbreviation') }}
        </div>
      </template>
      <template #table-options-menu>
        <v-list-item @click="exportSpecification">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportSpecification')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exportSpecificationPerElement">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportSpecificationPerElement')
          }}</v-list-item-title>
        </v-list-item>
      </template>
    </dynamic-data-table>
  </div>
</template>

<script>
import DynamicDataTable from '../../../components/DynamicDataTable.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { DHME_BILL_OF_MATERIAL } from '@/modules/modules';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { DateTime } from 'luxon';

export default {
  name: 'DhmeBillOfMaterialObjects',
  components: { DynamicDataTable },
  props: {
    tableTabState: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      assemblyStartDate: undefined,
      assemblyLocationWorkload: undefined,
      assemblyStartHour: undefined,
      tableLoading: false,
      tableRecords: [],
      tableHeaders: [],
      selectedCell: null,
      detailViewerRendered: false,
      selectedElement: null,
      elementCount: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeBillOfMaterialFocusedGroup',
      'dhmeBillOfMaterialResourceGroups',
      'dhmeBillOfMaterialModules',
      'dhmeBillOfMaterialNonModules',
      'dhmeBillOfMaterialElements',
      'dhmeBillOfMaterialResourceGroupObjects',
      'dhmeBillOfMaterialResourceGroupElements',
      'dhmeBillOfMaterialAssemblyTasks',
      'dhmeBillOfMaterialPlacementTasks',
      'dhmeBillOfMaterialAssemblyPhase',
    ]),
    objectSchedulePlacementHeaders() {
      let headers = [
        {
          text: this.text('table.objectType'),
          value: 'object_type',
        },
        {
          text: this.text('table.NLSFB'),
          value: 'NLSFB',
        },
        {
          text: this.text('table.total'),
          value: 'total',
          hasHeaderSlot: true,
        },
        {
          text: 'check',
          value: 'check',
          hasSlot: true,
        },
      ];

      this.dhmeBillOfMaterialPlacementTasks.forEach((task) => {
        headers.push({
          text: task.title,
          value: task.title,
          width: '100px',
          phase: true,
          name: task.title,
          hasHeaderSlot: true,
          hasSlot: true,
          sortable: false,
        });
      });

      return headers;
    },
    objectScheduleProductionHeaders() {
      const start = DateTime.fromFormat(
        this.dhmeBillOfMaterialAssemblyTasks[0].planned_start,
        'yyyy-MM-dd HH:mm:ss'
      );
      const end = DateTime.fromFormat(
        this.dhmeBillOfMaterialAssemblyTasks[
          this.dhmeBillOfMaterialAssemblyTasks.length - 1
        ].planned_end,
        'yyyy-MM-dd HH:mm:ss'
      );

      // Calculate the number of weeks between the start and end dates
      let assemblyWeeks = Math.ceil(end.diff(start, 'weeks').weeks);

      let headers = [
        {
          text: this.text('table.objectType'),
          value: 'object_type',
        },
        {
          text: this.text('table.NLSFB'),
          value: 'NLSFB',
        },
        {
          text: this.text('table.total'),
          value: 'total',
          hasHeaderSlot: true,
        },
        {
          text: 'check',
          value: 'check',
          hasSlot: true,
        },
      ];
      for (let i = 0; i <= assemblyWeeks; i++) {
        let currentWeekDate = start.plus({ weeks: i });

        // Explicitly set `weekStart` to Monday at the specified start time
        let weekStart = currentWeekDate
          .set({ weekday: 1 }) // Set to Monday
          .set({
            hour: this.assemblyStartHour,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

        // Explicitly set `weekEnd` to Friday of the same week at the specified end time
        let weekEnd = weekStart.plus({
          days: 4,
          hours: this.assemblyLocationWorkload,
        });

        headers.push({
          text: `PW${i + 1} | KW${weekStart.weekNumber}`,
          value: `week_${weekStart.weekNumber}`,
          width: '100px',
          week: weekStart.weekNumber,
          week_start: weekStart.toFormat('yyyy-MM-dd HH:mm:ss'),
          week_end: weekEnd.toFormat('yyyy-MM-dd HH:mm:ss'),
          date: currentWeekDate.toISO(),
          hasHeaderSlot: true,
          hasSlot: true,
          sortable: false,
        });
      }

      return headers;
    },

    elements() {
      return this.dhmeBillOfMaterialResourceGroupElements
        .filter(
          (mapping) =>
            mapping.group_code ===
            this.dhmeBillOfMaterialFocusedGroup.group_code
        )
        .map((mapping) => mapping.element_type)
        .sort((a, b) => a.localeCompare(b));
    },
    invalidData() {
      // TODO fetch invalid records
      return [];
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_BILL_OF_MATERIAL
      ).id;
    },
  },
  watch: {
    dhmeBillOfMaterialFocusedGroup(value) {
      switch (this.tableTabState) {
        case 'production':
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.calculatePlacementSchedule();
          break;
      }
    },
    async selectedElement(value) {
      let { elements } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ELEMENTS',
            project: this.project.id,
            as: 'elements',
            columns: [
              {
                name: 'element_type',
                conditions: [
                  {
                    operator: '=',
                    value: this.selectedElement,
                  },
                ],
              },
            ],
          },
        ],
      });

      this.elementCount = elements.records.length;
      switch (this.tableTabState) {
        case 'production':
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.calculatePlacementSchedule();
          break;
      }
    },
    tableTabState(value) {
      switch (this.tableTabState) {
        case 'production':
          this.tableHeaders = this.objectScheduleProductionHeaders;
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.tableHeaders = this.objectSchedulePlacementHeaders;
          this.calculatePlacementSchedule();
          break;
      }
    },
  },
  mounted() {
    if (this.dhmeBillOfMaterialAssemblyPhase.length === 1) {
      this.assemblyStartHour =
        this.dhmeBillOfMaterialAssemblyPhase[0].task_type.custom_5;
      this.assemblyLocationWorkload =
        this.dhmeBillOfMaterialAssemblyPhase[0].task_type.custom_6;

      this.assemblyStartDate = moment(
        this.dhmeBillOfMaterialAssemblyPhase[0].planned_start
      ).set({
        hour: this.assemblyStartHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      this.calculateProductionSchedule();
      this.tableHeaders = this.objectScheduleProductionHeaders;
    } else {
      this.$store.commit('showNotification', {
        content: 'Assembly phase not found',
        color: 'warning',
      });
    }
  },
  methods: {
    exportToCsv() {
      let copy = JSON.parse(JSON.stringify(this.tableRecords));
      copy.forEach((x) => {
        Object.keys(x).forEach((key) => {
          if (key.startsWith('week_')) {
            x[key] = x[key].count;
          }
        });
      });
      return copy;
    },
    exportSpecificationPerElement() {
      this.tableLoading = true;
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateSpecificationPerElement',
        {
          group: this.dhmeBillOfMaterialFocusedGroup,
          weeks: this.objectScheduleProductionHeaders
            .filter((header) => {
              return header.week;
            })
            .map((week) => {
              return {
                week: week.week,
                week_start: moment(week.week_start).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                week_end: moment(week.week_end).format('YYYY-MM-DD HH:mm:ss'),
              };
            }),
        }
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `Specificatie_${this.dhmeBillOfMaterialFocusedGroup.name}_per_onderdeel_${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        this.tableLoading = false;
      });
    },
    exportSpecification() {
      this.tableLoading = true;
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateSpecification',
        {
          objects: this.tableRecords.sort((a, b) =>
            a.object_type.localeCompare(b.object_type)
          ),
          group: this.dhmeBillOfMaterialFocusedGroup,
        }
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `Specificatie_${this.dhmeBillOfMaterialFocusedGroup.name}_${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        this.tableLoading = false;
      });
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.BillOfMaterial.objectSchedule.${path}`, params);
    },
    checkObjectTypeCount(item) {
      return (item.total / this.elementCount).toFixed(2);
    },
    viewerRendered(value) {
      this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);
      this.$refs['forge-viewer'].viewerService.setViewTo('top back right');
      document.getElementById('guiviewer3d-toolbar').style.visibility =
        'hidden';
      this.$refs['forge-viewer'].performanceModeToggle = true;
      this.detailViewerRendered = true;
    },
    calculatePlacementSchedule() {
      this.tableRecords = [];
      this.tableLoading = true;

      const body = {
        project: {
          id: this.project.id,
        },
        group: this.dhmeBillOfMaterialFocusedGroup.group_code,
        element: this.selectedElement,
        phases: this.dhmeBillOfMaterialPlacementTasks.map((task) => {
          return {
            id: task.id,
            name: task.title,
          };
        }),
      };
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getGroupObjectPlacementSchedule',
        body
      ).then((response) => {
        response.forEach((item) => {
          item.total = 0;
          this.dhmeBillOfMaterialPlacementTasks.forEach((task) => {
            item.total += item[task.title].count;
          });
        });
        this.tableRecords = response;
        this.tableLoading = false;
      });
    },
    calculateProductionSchedule() {
      this.tableRecords = [];
      this.tableLoading = true;

      const body = {
        project: {
          id: this.project.id,
        },
        group: this.dhmeBillOfMaterialFocusedGroup.group_code,
        element: this.selectedElement,
        weeks: this.objectScheduleProductionHeaders
          .filter((header) => {
            return header.week;
          })
          .map((week) => {
            return {
              week: week.week,
              week_start: week.week_start,
              week_end: week.week_end,
            };
          }),
      };
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getGroupObjectProductionSchedule',
        body
      ).then((response) => {
        if (response.length > 0) {
          let weeks = Object.keys(response[0]).filter((key) =>
            key.startsWith('week_')
          );
          response.forEach((item) => {
            item.total = 0;
            weeks.forEach((week) => {
              item.total += item[week].count;
            });
          });
        }
        this.tableRecords = response;
        this.tableLoading = false;
      });
    },
    getHeaderTotal(header) {
      let sum = 0;
      this.tableRecords.forEach((record) => {
        if (header.value.startsWith('week_')) {
          sum += record[header.value].count;
        } else {
          sum += record.total;
        }
      });
      return sum;
    },
  },
};
</script>
<style lang="scss" scoped>
.supplier-header {
  font-size: 18px;
  font-weight: 500;
}

.supplier-sub-header {
  font-style: italic;
}

.clickable-cell {
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
